/* eslint-disable max-len */
import React from 'react'

const ZestSvg = (props: any): React.ReactElement => (
  <svg viewBox="0 0 157.7 157.5" {...props}>
    <path
      d="M71.64.198c-8.532.882-17.532 3.15-23.724 5.994-.576.27-1.08.468-1.116.468-.198 0-4.14 1.98-5.652 2.826-.936.522-1.71.954-1.728.954-.36 0-8.622 5.562-9.09 6.12a17.7 17.7 0 01-.9.72 17.7 17.7 0 00-.9.72c-.054.054-.648.576-1.35 1.152-.684.594-2.646 2.448-4.32 4.14L19.8 26.37l36.414.054c20.016.018 36.396.072 36.396.108s-4.842 8.154-10.764 18.036C75.924 54.45 61.254 78.948 49.248 99s-22.122 36.9-22.464 37.44l-.612.99.774.702c7.02 6.318 17.19 12.168 26.694 15.318 22.32 7.416 47.34 4.608 66.51-7.434 5.724-3.6 10.404-7.344 15.174-12.186l2.664-2.7-36.54-.09-36.54-.09 5.184-8.55c2.844-4.698 6.948-11.466 9.108-15.03s4.716-7.776 5.67-9.36c.954-1.584 3.366-5.562 5.346-8.82 7.434-12.276 19.836-32.76 22.788-37.62 1.674-2.772 3.276-5.4 3.546-5.85.27-.45 2.124-3.528 4.14-6.84 2.016-3.312 5.364-8.856 7.452-12.312l3.798-6.264-.81-.702C119.448 9.342 105.786 3.06 89.91.666 85.878.036 75.618-.216 71.64.198m68.814 29.898c-.144.234-2.034 3.33-4.194 6.894s-4.716 7.776-5.67 9.36c-.954 1.584-3.402 5.598-5.4 8.91-2.016 3.312-4.554 7.524-5.67 9.36-1.116 1.836-3.78 6.246-5.94 9.81-2.16 3.564-5.418 8.946-7.254 11.97-1.836 3.024-3.546 5.85-3.816 6.3-.27.45-1.89 3.114-3.6 5.94s-4.194 6.912-5.508 9.09-3.33 5.49-4.464 7.38l-2.07 3.42 29.916.054c16.452.018 30.006.018 30.114-.036.576-.216 4.302-7.758 5.688-11.538 1.26-3.42 1.602-4.464 2.286-6.876 4.86-17.28 3.492-37.296-3.582-52.884-1.854-4.068-2.412-5.148-4.842-9.27-1.332-2.286-4.536-6.912-5.616-8.154-.054-.072-.234.054-.378.27m-129.87 9.108c-.054.18-.252.522-.432.774-2.7 3.924-6.552 13.986-8.406 22.032C-.09 69.948-.504 80.1.648 89.1c1.224 9.522 4.5 19.53 9.072 27.63 2.664 4.716 7.218 11.376 7.686 11.214.054-.018 3.258-5.274 7.074-11.664 6.984-11.664 16.128-26.91 35.316-58.95 5.688-9.504 10.512-17.55 10.71-17.856l.342-.576H40.77c-28.476-.018-30.078 0-30.186.306"
      fill="#21ce3e"
    />
  </svg>
)

export default ZestSvg
